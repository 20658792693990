/* import __COLOCATED_TEMPLATE__ from './installation-step.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export interface Args {
  stepNumber: number;
  stepTitle?: string;
  stepSubtitle?: string;
  stepSubtitleArticleLink?: string;
  stepSubtitleArticleLinkTitle?: string;
  stepSubtitleLink?: string;
  stepSubtitleLinkTitle?: string;
  disabled?: boolean;
}

interface Signature {
  Blocks: {
    default: [];
  };
  Args: Args;
  Element: HTMLElement;
}

export default class InstallationStep extends Component<Signature> {
  @service declare appService: $TSFixMe;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::InstallationStep': typeof InstallationStep;
    'installation-new/installation-step': typeof InstallationStep;
  }
}
