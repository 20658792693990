/* import __COLOCATED_TEMPLATE__ from './connected-to-intercom.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type { Router } from '@ember/routing';
import { action } from '@ember/object';

interface Args {
  analyticsContext: string;
}

export default class ConnectedToIntercom extends Component<Args> {
  @service declare router: Router;
  @service declare intercomEventService: any;

  @action
  navigateToMessengerSettings() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'connected_to_intercom_navigate_to_messenger_settings_button',
      place: 'settings.installation',
      context: this.args.analyticsContext,
    });

    this.router.transitionTo('apps.app.messenger.web', {
      queryParams: { section: 'show-launcher', tab: 'content' },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationInstructions::ConnectedToIntercom': typeof ConnectedToIntercom;
  }
}
