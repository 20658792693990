/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';

export interface Args {
  isFolderSelected: boolean;
}

interface Signature {
  Args: Args;
}

export default class KnowledgeBaseBrowseEmptyState extends Component<Signature> {
  @service declare router: RouterService;
  @service declare session: Session;

  @action goToKnowledgeHub() {
    this.router.transitionTo('apps.app.knowledge-hub.all-content', this.session.workspace.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::Browse::EmptyState': typeof KnowledgeBaseBrowseEmptyState;
    'inbox2/knowledge-base/browse/empty-state': typeof KnowledgeBaseBrowseEmptyState;
  }
}
