/* import __COLOCATED_TEMPLATE__ from './sidebar-header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import { KnowledgeBaseFolder } from 'embercom/objects/inbox/knowledge-base/folder';
import type CustomIntlService from 'embercom/services/intl';

interface Args {
  isCollapsed: boolean;
  activeContent?: KnowledgeBaseContent;
  selectedFolder?: KnowledgeBaseFolder;
  goBack: () => void;
  closePanel: () => void;
}

interface Signature {
  Args: Args;
}

export default class KnowledgeBaseSidebarHeader extends Component<Signature> {
  @service declare intl: CustomIntlService;

  get currentlyViewedObject(): KnowledgeBaseContent | KnowledgeBaseFolder | undefined {
    return this.args.activeContent || this.args.selectedFolder;
  }

  get shouldShowBackButton() {
    return !!this.currentlyViewedObject;
  }

  get isViewingFolder() {
    return this.currentlyViewedObject instanceof KnowledgeBaseFolder;
  }

  get headerTitle() {
    return this.currentlyViewedObject?.title || this.intl.t('inbox.knowledge-base.sidebar.heading');
  }

  get emoji(): string | undefined {
    return this.isViewingFolder ? this.args.selectedFolder?.emoji : undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::SidebarHeader': typeof KnowledgeBaseSidebarHeader;
    'inbox2/knowledge-base/sidebar-header': typeof KnowledgeBaseSidebarHeader;
  }
}
