/* import __COLOCATED_TEMPLATE__ from './content-details.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import { type KnowledgeBaseSearchResult } from 'embercom/objects/inbox/knowledge-base/search-result';

interface Args {
  content: KnowledgeBaseContent | KnowledgeBaseSearchResult;
}

const ContentDetails = templateOnlyComponent<Args>();
export default ContentDetails;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::ContentDetails': typeof ContentDetails;
    'inbox2/knowledge-base/content-details': typeof ContentDetails;
  }
}
