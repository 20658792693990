/* import __COLOCATED_TEMPLATE__ from './ai-chatbot.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type Session from 'embercom/services/session';
import storage from 'embercom/vendor/intercom/storage';
import type IntlService from 'ember-intl/services/intl';
import type InboxCounters from 'embercom/services/inbox2-counters';
interface Args {
  pinnedInboxes: Inbox[];
  isHoverable: boolean;
}

interface Signature {
  Args: Args;
}

export default class AiChatbot extends Component<Signature> {
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare inbox2Counters: InboxCounters;

  @tracked openSectionId: string | undefined;
  @tracked isHovering = false;

  constructor(owner: any, args: Args) {
    super(owner, args);
    let label = `inbox.inbox-list.ai-chatbot-accordion-${this.session.workspace.id}`;
    if (this.args.pinnedInboxes.length === 0) {
      this.openSectionId = undefined;
    } else if (storage.get(label) === null || storage.get(label) === true) {
      this.openSectionId = 'section_a';
    } else {
      this.openSectionId = undefined;
    }
  }

  get isDisabled() {
    return this.args.pinnedInboxes.length === 0;
  }

  get showPlusButton() {
    return !this.args.pinnedInboxes.length;
  }

  noOp = (_: Inbox) => {};

  @action trackOpenPopover() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'inbox_list_dropdown',
      inbox_type: InboxType.View,
    });
  }

  @action trackInboxHover() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'hover',
      object: 'inbox',
      inbox_type: InboxType.View,
    });
  }

  @action onSectionDidOpen() {
    this.inbox2Counters.refreshCounters(this.args.pinnedInboxes);
  }

  @action onOpenSectionChange(sectionId: string) {
    if (sectionId === 'section_a') {
      this.openSectionId = sectionId;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        place: 'inbox',
        object: 'inbox_list_dropdown_ai_chatbot',
        section: 'inbox',
      });
      storage.set(`inbox.inbox-list.ai-chatbot-accordion-${this.session.workspace.id}`, true);
    } else {
      this.openSectionId = undefined;
      storage.set(`inbox.inbox-list.ai-chatbot-accordion-${this.session.workspace.id}`, false);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::Folders::Default::AiChatbot': typeof AiChatbot;
  }
}
