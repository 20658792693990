/* import __COLOCATED_TEMPLATE__ from './phone-details.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type SidebarCallDetails from 'embercom/objects/inbox/sidebar-call-details';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';

interface Args {
  callId: number;
  callDetails: SidebarCallDetails;
  adminAssignee: AdminSummary | undefined;
  userSummary: UserSummary | undefined;
  isCollapsed: boolean;
  closeSidebar: () => void;
  goBack: () => void;
  deleteRecording: () => void;
}

type Option = {
  text: string;
  icon: string;
  onSelectItem: () => void;
  isDestructive: boolean;
  isDisabled: boolean;
};

export default class Inbox2PhoneDetails extends Component<Args> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @tracked selectedTab = 'phone';
  @tracked showConfirmationModal = false;

  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;

  @action
  changeTab(tab: string) {
    this.selectedTab = tab;

    if (tab === 'transcript' && this.args.callDetails.transcript) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'transcript_tab',
        place: 'phone_sidebar',
      });
    }
  }

  @action
  handleRecordingPlayback() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'playback_triggered',
      object: 'recording',
      place: 'phone_sidebar',
    });
  }

  get admin() {
    return this.inbox2AssigneeSearch.findAdminById(Number(this.args.callDetails.adminId));
  }

  private downloadFile(url: string): void {
    safeWindowOpen(url, '_blank');
  }

  enableConfirmationModal() {
    this.showConfirmationModal = true;
  }

  @action
  performDelete() {
    this.showConfirmationModal = false;
    this.args.deleteRecording();
  }

  get transcriptStatusText() {
    if (!this.args.callDetails?.additionalCallDetailsLoaded) {
      return this.intl.t('inbox.phone-details-sidebar.transcript.loading');
    }

    if (
      this.args.callDetails.transcriptStatus === 'not_submitted' ||
      this.args.callDetails.transcriptStatus === 'submitted'
    ) {
      return this.intl.t('inbox.phone-details-sidebar.transcript.processing');
    }

    if (this.args.callDetails.recordingDeleted) {
      return this.intl.t('inbox.phone-details-sidebar.transcript.deleted');
    }

    return this.intl.t('inbox.phone-details-sidebar.transcript.none');
  }

  get groupList() {
    let optionList: Option[];
    let downloadRecording: Option;
    let downloadTranscript: Option;
    let deleteAll: Option;

    downloadRecording = {
      text: this.intl.t('inbox.phone-details-sidebar.download.recording'),
      icon: 'download',
      onSelectItem: () =>
        this.args.callDetails?.recordingUrl
          ? this.downloadFile(this.args.callDetails.recordingUrl)
          : undefined,
      isDestructive: false,
      isDisabled: !this.args.callDetails?.recordingUrl || this.args.callDetails?.recordingDeleted,
    };

    downloadTranscript = {
      text: this.intl.t('inbox.phone-details-sidebar.download.transcript'),
      icon: 'download',
      onSelectItem: () =>
        this.args.callDetails?.transcriptUrl
          ? this.downloadFile(this.args.callDetails.transcriptUrl)
          : undefined,
      isDestructive: false,
      isDisabled: !this.args.callDetails?.transcriptUrl || this.args.callDetails.recordingDeleted,
    };

    deleteAll = {
      text: this.intl.t('inbox.phone-details-sidebar.delete-all'),
      icon: 'trash',
      onSelectItem: () => {
        this.enableConfirmationModal();
      },
      isDestructive: true,
      isDisabled:
        !(this.args.callDetails?.recordingUrl || this.args.callDetails?.transcriptUrl) ||
        this.args.callDetails.recordingDeleted,
    };

    optionList = [downloadRecording, downloadTranscript, deleteAll];

    return [
      {
        items: optionList,
      },
    ];
  }

  get showCsatRating() {
    return (
      this.args.callDetails?.csatRating !== null && this.args.callDetails?.csatRating !== 'N/A'
    );
  }

  @action
  speakerLabel(speaker: string) {
    switch (speaker.substring(0, 4)) {
      case 'voic':
        return this.intl.t('inbox.phone-details-sidebar.speakers.voicemail');
      case 'spk_': {
        let speaker_number = (parseInt(speaker.substring(4, speaker.length), 10) || 0) + 1;
        return `${this.intl.t('inbox.phone-details-sidebar.speakers.speaker', {
          speakerIdentifier: speaker_number,
        })}`;
      }
      default:
        return speaker;
    }
  }

  get detailedStateString(): string {
    switch (this.args.callDetails?.detailedState) {
      case 'routing':
        return this.intl.t('calling.call-summary.detailed-state.routing');
      case 'in_queue':
        return this.intl.t('calling.call-summary.detailed-state.in-queue');
      case 'in_progress':
        return this.intl.t('calling.call-summary.detailed-state.in-progress');
      case 'on_hold':
        return this.intl.t('calling.call-summary.detailed-state.on-hold');
      case 'answered':
        return this.intl.t('calling.call-summary.detailed-state.answered');
      case 'answered_by_fin':
        return this.intl.t('calling.call-summary.detailed-state.answered_by_fin');
      case 'fin_requested_callback':
        return this.intl.t('calling.call-summary.detailed-state.fin_requested_callback');
      case 'fin_resolved':
        return this.intl.t('calling.call-summary.detailed-state.fin_resolved');
      case 'ended_by_workflow':
        return this.intl.t('calling.call-summary.detailed-state.ended-by-workflow');
      case 'voicemail_left':
        return this.intl.t('calling.call-summary.detailed-state.voicemail-left');
      case 'abandoned_in_voicemail':
        return this.intl.t('calling.call-summary.detailed-state.abandoned-in-voicemail');
      case 'abandoned_on_hold':
        return this.intl.t('calling.call-summary.detailed-state.abandoned-on-hold');
      case 'abandoned_in_queue':
        return this.intl.t('calling.call-summary.detailed-state.abandoned-in-queue');
      case 'abandoned_routing':
        return this.intl.t('calling.call-summary.detailed-state.abandoned-routing');
      case 'initiated':
        return this.intl.t('calling.call-summary.detailed-state.initiated');
      case 'no_answer':
        return this.intl.t('calling.call-summary.detailed-state.no-answer');
      case 'awaiting_callback':
        return this.intl.t('calling.call-summary.detailed-state.awaiting-callback');
      case 'answered_callback':
        return this.intl.t('calling.call-summary.detailed-state.answered-callback');
      case 'unanswered_callback':
        return this.intl.t('calling.call-summary.detailed-state.unanswered-callback');
      case 'closed_callback':
        return this.intl.t('calling.call-summary.detailed-state.closed-callback');
      default:
        return this.intl.t('calling.call-summary.detailed-state.unknown');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PhoneDetails': typeof Inbox2PhoneDetails;
    'inbox2/phone-details': typeof Inbox2PhoneDetails;
  }
}
