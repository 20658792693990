/* import __COLOCATED_TEMPLATE__ from './workspaces-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { type WorkspaceForList } from '../../../objects/workspace';

interface Args {
  item: {
    componentAttrs: {
      workspacesList: Array<WorkspaceForList>;
      hideAddNewWorkspace?: boolean;
    };
  };
}

interface Signature {
  Args: Args;
}

export default class WorkspacesSwitcherPopover extends Component<Signature> {
  @service declare session: Session;
  @service declare router: Router;
  @tracked workspacesList: Array<WorkspaceForList> = [];
  @tracked hideAddNewWorkspace = false;
  @tracked workspaceFilter = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.workspacesList = args.item.componentAttrs.workspacesList;
    this.hideAddNewWorkspace = args.item.componentAttrs.hideAddNewWorkspace ?? false;
  }

  get currentWorkspace() {
    return this.session.workspace;
  }

  get showWorkspaceFilter() {
    return this.workspacesList.length > 5;
  }

  get filteredWorkspacesList() {
    if (!this.showWorkspaceFilter) {
      return this.workspacesList;
    }
    let regexp = new RegExp(this.workspaceFilter, 'i');
    return this.workspacesList.filter((app) => regexp.test(app.name));
  }

  // This function is a Popper.js modifier (https://popper.js.org/docs/v2/modifiers/)
  // that keeps the dimensions of the app switcher popover the same as those of
  // the primary nav menu popover. This behavior is a design requirement from
  // the "early 2018" brand refresh.
  matchPopoverDimensionsToMenuPopover(popperModifierData: any) {
    let { reference: referenceElement, popper: popperElement } = popperModifierData.state.elements;
    let referenceElementPopoverContainer = referenceElement.closest('[data-popover-content]');
    popperElement.style.width = `${referenceElementPopoverContainer.offsetWidth}px`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::WorkspacesSwitcher': typeof WorkspacesSwitcherPopover;
    'inbox2/user-menu/workspaces-switcher': typeof WorkspacesSwitcherPopover;
  }
}
