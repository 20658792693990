/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type InboxState from 'embercom/services/inbox-state';
import {
  INBOX_STATE_ICON_MAPPING,
  type InboxSortOption,
  type InboxStateOption,
} from 'embercom/models/data/inbox/inbox-filters';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';
import {
  paramsToSortOptions,
  sortOptionsToParams,
  type SortParams,
} from 'embercom/services/inbox-api';

interface Arguments {
  selectedStateOption?: InboxStateOption;
  selectedSortOption?: SortParams;
  onStateOptionChanged?: (newState: InboxStateOption) => void;
  onSortOptionChanged?: (newSort: SortParams) => void;
  availableSortOptions?: InboxSortOption[];
  selectedLabelPrefix?: string | number;
  dropdownClass?: string;
}

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

enum Section {
  State = 'state',
  SortBy = 'sort-by',
}

export default class Inbox2LeftNavConversationListFilters extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;
  @service declare session: Session;

  get label() {
    this.intl.primaryLocale; // We must access the locale to make this getter aware that it is dependent on it.
    if (this.args.onStateOptionChanged && this.args.selectedStateOption) {
      return this.intl.t(
        `inbox.conversation-filters.${Section.State}.${this.args.selectedStateOption}`,
      );
    } else if (this.args.onSortOptionChanged && this.args.selectedSortOption) {
      let sortOption = paramsToSortOptions(this.args.selectedSortOption);
      return this.intl.t(`inbox.conversation-filters.${Section.SortBy}.${sortOption}`);
    } else {
      return '';
    }
  }

  get formattedSelectedLabelPrefix() {
    if (typeof this.args.selectedLabelPrefix === 'number') {
      return this.intl.formatNumber(this.args.selectedLabelPrefix);
    }
    return this.args.selectedLabelPrefix;
  }

  get items() {
    this.intl.primaryLocale; // We must access the locale to make this getter aware that it is dependent on it.

    let items = [];

    if (this.args.onStateOptionChanged) {
      let conversationStateItems = Object.values(ConversationState).map((option) => {
        return {
          text: this.intl.t(`inbox.conversation-filters.${Section.State}.${option}`),
          value: {
            section: Section.State,
            value: option,
          },
          icon: INBOX_STATE_ICON_MAPPING[option],
          isSelected: option === this.args.selectedStateOption,
          component: 'inbox2/left-nav/conversation-list/filters-item',
        };
      });

      // Hide the Snoozed Filter in Standalone Apps as this concept doesn't exist there
      if (this.session.workspace.hasLightStandaloneInbox) {
        conversationStateItems = conversationStateItems.filter(
          (item) => item.value.value !== ConversationState.Snoozed,
        );
      }

      items.push({
        items: conversationStateItems,
      });

      if (this.session.workspace.ticketStateFiltering) {
        let ticketStates = Object.values(TicketSystemState).map(
          (systemState: TicketSystemState) => {
            return {
              text: this.intl.t(`inbox.ticket-state.${systemState}`),
              value: {
                section: Section.State,
                value: systemState,
              },
              icon: 'ticket-state',
              systemState,
              isSelected: systemState === this.args.selectedStateOption,
              component: 'inbox2/left-nav/conversation-list/filters-item',
            };
          },
        );

        items.push({
          items: ticketStates,
        });
      }
    }

    if (this.args.onSortOptionChanged) {
      let sortItems =
        this.args.availableSortOptions?.map((option) => {
          return {
            text: this.intl.t(`inbox.conversation-filters.${Section.SortBy}.${option}`),
            value: {
              section: Section.SortBy,
              value: option,
            },
            isSelected:
              this.args.selectedSortOption &&
              option === paramsToSortOptions(this.args.selectedSortOption),
          };
        }) ?? [];

      items.push({
        heading: this.intl.t(`inbox.conversation-filters.${Section.SortBy}.heading`),
        items: sortItems,
      });
    }

    return items;
  }

  @action select(option: { section: Section; value: InboxStateOption | InboxSortOption }): void {
    if (option.section === Section.State) {
      this.args.onStateOptionChanged &&
        this.args.onStateOptionChanged(option.value as InboxStateOption);
    } else {
      this.args.onSortOptionChanged &&
        this.args.onSortOptionChanged(sortOptionsToParams(option.value as InboxSortOption));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationList::Filters': typeof Inbox2LeftNavConversationListFilters;
    'inbox2/left-nav/conversation-list/filters': typeof Inbox2LeftNavConversationListFilters;
  }
}
