/* import __COLOCATED_TEMPLATE__ from './toggle-left-nav.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { timeout } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';

import type InboxState from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { type Hotkey } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';

// Duration of the inbox list collapse animation
const COLLAPSE_ANIMATION_DURATION = ENV.APP._150MS;

interface Args {}

interface Signature {
  Element: HTMLSpanElement;
  Args: Args;
}

export default class ToggleLeftNav extends Component<Signature> {
  @service declare inbox2Counters: Inbox2Counters;
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare intercomEventService: any;

  readonly handleNotEditorHotkey;
  readonly toggleLeftNavHotkey: Hotkey;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.handleNotEditorHotkey = this.inboxHotkeys.handleNotEditorHotkey;
    this.toggleLeftNavHotkey = this.inboxHotkeys.hotkeysMap[HotkeyID.ToggleLeftNav];
  }

  get isLeftNavVisible() {
    return this.inboxState.isInboxListPinned;
  }

  get mentionsCount(): number {
    return this.inbox2Counters.countForInbox({ id: 'mentions', type: InboxType.Mentions });
  }

  @dropTask *toggleLeftNavVisibility(options = { keyboardShortcutUsed: false }) {
    this.inboxState.toggleLeftNavVisibility();

    if (this.inboxState.isInboxListHidden) {
      yield timeout(COLLAPSE_ANIMATION_DURATION);
    }

    if (!options.keyboardShortcutUsed) {
      let focusIsOnToggleButton = Boolean(
        document.activeElement?.closest('[data-left-nav-toggle-button]'),
      );
      if (focusIsOnToggleButton) {
        (document.activeElement as HTMLElement).blur();
      }
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: this.inboxState.isInboxListHidden ? 'hidden' : 'shown',
      object: 'inbox_list_sidebar',
      section: options.keyboardShortcutUsed ? 'shortcuts' : undefined,
      shortcut_key: options.keyboardShortcutUsed,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ToggleLeftNav': typeof ToggleLeftNav;
    'inbox2/left-nav/toggle-left-nav': typeof ToggleLeftNav;
  }
}
