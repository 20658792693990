/* import __COLOCATED_TEMPLATE__ from './error-state.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  headingMessage: string;
  retry: () => void;
}

interface Signature {
  Args: Args;
}

const KnowledgeBaseErrorState = templateOnlyComponent<Signature>();
export default KnowledgeBaseErrorState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::ErrorState': typeof KnowledgeBaseErrorState;
    'inbox2/knowledge-base/error-state': typeof KnowledgeBaseErrorState;
  }
}
