/* import __COLOCATED_TEMPLATE__ from './android-installation-custom-data-snippet-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import baseCustomDataSnippetComponent from 'embercom/components/installation-instructions/base-custom-data-snippet-component';

export default baseCustomDataSnippetComponent.extend({
  language: 'java',
});
