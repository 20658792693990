/* import __COLOCATED_TEMPLATE__ from './conversation-options.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type Router from '@ember/routing/router-service';
import type ConversationOptions from 'embercom/services/inbox2-conversation-options-service';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import Mentions from 'embercom/objects/inbox/inboxes/mentions';

interface Args {
  conversation: ConversationSummary | Conversation;
  onRemoveSla: () => void;
}

interface Signature {
  Args: Args;
}

export enum DropdownOption {
  removeSla = 'remove-sla',
  reOpen = 're-open',
  snooze = 'snooze',
  unSnooze = 'un-snooze',
  markPriority = 'mark-priority',
  removePriority = 'remove-priority',
  markUnread = 'mark-unread',
  clearMention = 'clear-mention',
}

type OptionItem = {
  text: string;
  id: DropdownOption;
  isSelected: boolean;
  icon: string;
};

const allOptions = (intl: IntlService): OptionItem[] => [
  {
    text: intl.t('inbox.conversation-options.options.re-open'),
    id: DropdownOption.reOpen,
    isSelected: false,
    icon: 'reopen-conversation',
  },
  {
    text: intl.t('inbox.conversation-options.options.snooze'),
    id: DropdownOption.snooze,
    isSelected: false,
    icon: 'snooze',
  },
  {
    text: intl.t('inbox.conversation-options.options.un-snooze'),
    id: DropdownOption.unSnooze,
    isSelected: false,
    icon: 'snooze',
  },
  {
    text: intl.t('inbox.conversation-options.options.mark-priority'),
    id: DropdownOption.markPriority,
    isSelected: false,
    icon: 'star-outlined',
  },
  {
    text: intl.t('inbox.conversation-options.options.remove-priority'),
    id: DropdownOption.removePriority,
    isSelected: false,
    icon: 'star-outlined',
  },
  {
    text: intl.t('inbox.conversation-options.options.remove-sla'),
    id: DropdownOption.removeSla,
    isSelected: false,
    icon: 'inbox2/sla-icon',
  },
  {
    text: intl.t('inbox.conversation-options.options.mark-unread'),
    id: DropdownOption.markUnread,
    isSelected: false,
    icon: 'not-seen-path',
  },
  {
    text: intl.t('inbox.conversation-options.restricted-options.clear-mention'),
    id: DropdownOption.clearMention,
    isSelected: false,
    icon: 'inapp-badge',
  },
];

export default class Inbox2ConversationOptionDropdownComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare session: Session;
  @service declare router: Router;
  @service declare inbox2ConversationOptionsService: ConversationOptions;
  @service declare inboxState: InboxState;

  get availableOptionsKeys() {
    let conversation = this.args.conversation;
    let priorityOptionKey = conversation.priority
      ? DropdownOption.removePriority
      : DropdownOption.markPriority;
    let snoozeOptionKey = conversation.isSnoozed ? DropdownOption.unSnooze : DropdownOption.snooze;
    let slaOptionKey;
    if (
      this.session.teammate.permissions.canRemoveConversationSlas &&
      conversation.nextBreachTime
    ) {
      slaOptionKey = DropdownOption.removeSla;
    }

    let markUnreadOptionKey =
      this.inboxState.activeInbox instanceof Mentions && conversation.isRead
        ? DropdownOption.markUnread
        : undefined;

    // Ideally we only add this as an option only if the admin on the conversation has unread mentions.
    // However, we don't currently have that information available so we always show it.
    // We should add this restriction once we have unread mentions counts available.
    if (conversation.redacted) {
      return [DropdownOption.clearMention];
    }

    if (conversation.isOpen) {
      return [priorityOptionKey, snoozeOptionKey, slaOptionKey, markUnreadOptionKey].compact();
    }

    if (conversation.isClosed) {
      return [
        DropdownOption.reOpen,
        priorityOptionKey,
        slaOptionKey,
        markUnreadOptionKey,
      ].compact();
    }

    if (conversation.isSnoozed) {
      return [snoozeOptionKey, priorityOptionKey, slaOptionKey, markUnreadOptionKey].compact();
    }

    return [];
  }

  get canHaveConversationActions() {
    return !this.session.showLightInbox;
  }

  get optionsItems() {
    if (!this.canHaveConversationActions) {
      return [];
    }

    let availableOptions = this.availableOptionsKeys.map((key) => {
      return allOptions(this.intl).find((option) => option.id === key);
    });

    return availableOptions;
  }

  @action onMoreOptionsSelectionChange(item: OptionItem) {
    switch (item.id) {
      case DropdownOption.reOpen:
        this.inbox2ConversationOptionsService.openConversation(this.args.conversation);
        break;

      case DropdownOption.snooze:
        this.inbox2ConversationOptionsService.snoozeConversation(this.args.conversation);
        break;

      case DropdownOption.unSnooze:
        this.inbox2ConversationOptionsService.openConversation(this.args.conversation);
        break;

      case DropdownOption.markPriority:
        this.inbox2ConversationOptionsService.togglePriority(this.args.conversation, true);
        break;

      case DropdownOption.removePriority:
        this.inbox2ConversationOptionsService.togglePriority(this.args.conversation, false);
        break;

      case DropdownOption.removeSla:
        this.args.onRemoveSla();
        break;

      case DropdownOption.markUnread:
        this.inbox2ConversationOptionsService.markUnread(this.args.conversation);
        break;

      case DropdownOption.clearMention:
        this.inbox2ConversationOptionsService.markMentionsAsRead(this.args.conversation);
        break;

      default:
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationCard::ConversationOptions': typeof Inbox2ConversationOptionDropdownComponent;
    'inbox2/left-nav/conversation-card/conversation-options': typeof Inbox2ConversationOptionDropdownComponent;
  }
}
