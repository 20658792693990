/* import __COLOCATED_TEMPLATE__ from './inbox-list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import Admin from 'embercom/objects/inbox/inboxes/admin';
import All from 'embercom/objects/inbox/inboxes/all';
import { type InboxesMetaTotalsWireFormat } from 'embercom/objects/inbox/inboxes/inbox';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { parseUpdatedCount } from 'embercom/objects/inbox/inboxes/inbox';
import Mentions from 'embercom/objects/inbox/inboxes/mentions';
import CreatedByYou from 'embercom/objects/inbox/inboxes/created-by-you';
import Team from 'embercom/objects/inbox/inboxes/team';
import Unassigned from 'embercom/objects/inbox/inboxes/unassigned';
import View from 'embercom/objects/inbox/inboxes/view';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import type Session from 'embercom/services/session';

import type Router from '@ember/routing/router-service';
import { showUnderConstructionBanner } from 'embercom/lib/under-construction-banner';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { type PinnedFolder } from 'embercom/objects/inbox/pinned-folder';
import type ViewSummary from 'embercom/objects/inbox/view-summary';
import type Inbox2OptIn from 'embercom/services/inbox2-opt-in';
import type IntlService from 'embercom/services/intl';
import type LogService from 'embercom/services/log-service';
import { type CountersUpdatedEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import {
  CountersUpdatedSource,
  NexusFallbackPoller,
  NexusStatusEvents,
} from 'embercom/services/nexus';
import type PermissionsModal from 'embercom/services/permissions-modal';
import type Snackbar from 'embercom/services/snackbar';
import storage from 'embercom/vendor/intercom/storage';
// @ts-ignore
import { dedupeTracked } from 'tracked-toolbox';
import { type InboxFolder } from 'embercom/objects/inbox/inbox-folder';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';
import { put, ajaxDelete } from 'embercom/lib/ajax';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';
import Spam from 'embercom/objects/inbox/inboxes/spam';
import type TracingService from 'embercom/services/tracing';

interface Args {}

interface Signature {
  Args: Args;
}

const INBOX_3DOTMENU = 'inbox-3dotmenu';

export default class InboxList extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare nexus: Nexus;
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare inboxState: InboxState;
  @service declare router: Router;
  @service declare permissionsModal: PermissionsModal;
  @service declare permissionsService: any;
  @service declare intl: IntlService;
  @service declare inbox2OptIn: Inbox2OptIn;
  @service declare inbox2Counters: Inbox2Counters;
  @service declare logService: LogService;
  @service declare snackbar: Snackbar;
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare tracing: TracingService;

  @tracked pinnedFolders: PinnedFolder[] = [];
  @tracked pinnedInboxes?: Inbox[];
  @tracked inboxTotals?: InboxesMetaTotalsWireFormat;
  @tracked customFolderId?: number;

  @dedupeTracked canRenderOnboardingPopover = false;

  @tracked creatingView = false;
  @tracked editingView: false | ViewSummary = false;
  @tracked deletingView: false | ViewSummary = false;
  @tracked isReorderingPinnedFolders = false;
  @tracked creatingCustomFolder = false;
  @tracked editingCustomFolder: false | InboxFolder = false;

  private poller = new NexusFallbackPoller(this);

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.nexus.on(NexusStatusEvents.Reconnected, this.loadPinnedInboxes);
    this.poller.start(() => this.loadPinnedInboxes());
  }

  willDestroy() {
    super.willDestroy();
    this.nexus.off(NexusStatusEvents.Reconnected, this.loadPinnedInboxes);
    this.poller.stop();
  }

  @action async loadPinnedInboxes() {
    let result = await this.inboxApi.listInboxes({
      pinned: true,
      includeFolders: true,
      includeCounts: true,
    });

    this.inbox2Counters.updateCountsFromInboxes(result.inboxes);

    this.pinnedFolders = (result.folders ?? []).sortBy('sortOrder');

    this.inboxTotals = result.totals;
    this.pinnedInboxes = (result.inboxes ?? []).sortBy('sortOrder');
    // On initial load these cause a layout shift of things below them, throwing off the OnboardingPopover placement
    this.canRenderOnboardingPopover = true;
  }

  @use customFoldersResource = AsyncData<InboxFolder[]>(async ({ signal }) => {
    let inboxFolders = await this.inboxApi.fetchFolders({ signal });
    inboxFolders.map((folder) => {
      this.updateCustomFolderCounts(folder);
    });

    return inboxFolders;
  });

  async refreshPinnedViews() {
    let result = await this.inboxApi.listInboxes({
      pinned: true,
      types: [InboxType.View],
      includeCounts: true,
    });
    this.inbox2Counters.updateCountsFromInboxes(result.inboxes);
    let currentWithoutViews = (this.pinnedInboxes || []).reject((i) => i instanceof View);
    this.pinnedInboxes = [...currentWithoutViews, ...(result.inboxes ?? [])].sortBy('sortOrder');
  }

  get customFolders(): InboxFolder[] | undefined {
    return this.customFoldersResource.value;
  }

  get yourInbox(): Inbox | undefined {
    return this.pinnedInboxes?.find(
      (i) => i instanceof Admin && i.id === this.session.teammate.id.toString(),
    );
  }

  get yourInboxId(): number {
    return this.session.teammate.id;
  }

  get mentionsInbox(): Inbox | undefined {
    return this.pinnedInboxes?.find((i) => i instanceof Mentions);
  }

  get createdByYouInbox(): Inbox | undefined {
    return this.pinnedInboxes?.find((i) => i instanceof CreatedByYou);
  }

  get allInbox(): Inbox | undefined {
    return this.pinnedInboxes?.find((i) => i instanceof All);
  }

  get isSingleConversationRoute(): boolean {
    return this.router.currentRouteName === 'inbox.workspace.inbox.conversation.conversation';
  }

  get unassignedInbox(): Inbox | undefined {
    return this.pinnedInboxes?.find((i) => i instanceof Unassigned);
  }

  get spamInbox(): Inbox | undefined {
    return this.pinnedInboxes?.find((i) => i instanceof Spam);
  }

  get adminInboxes(): Inbox[] {
    return (this.pinnedInboxes || []).filter(
      (i) => i instanceof Admin && i.id !== this.session.teammate.id.toString(),
    );
  }

  get teamInboxes(): Inbox[] {
    return (this.pinnedInboxes || []).filter((i) => i instanceof Team);
  }

  get views(): Inbox[] {
    return (this.pinnedInboxes || []).filter(
      (i) => i instanceof View && !i?.viewSummary?.isAiChatbotView,
    );
  }

  get inboxes(): Inbox[] {
    return (this.pinnedInboxes || []).filter(
      (i) => i !== this.yourInbox && i !== this.mentionsInbox && !(i instanceof View),
    );
  }

  get aiChatbotInboxes(): Inbox[] {
    return (this.pinnedInboxes || []).filter(
      (i) => i instanceof View && i?.viewSummary?.isAiChatbotView,
    );
  }

  get shouldShowAiChatbotFolder() {
    return true;
  }

  get showUnderConstructionBanner() {
    return showUnderConstructionBanner(this.session.workspace);
  }

  get isChannelSwitcherActive() {
    let features = this.session.workspace.features ?? [];
    return features.includes('inbound-phone-call');
  }

  get isLeftNavVisible() {
    return this.inboxState.isInboxListPinned;
  }

  get groupList() {
    let items = [];

    if (this.customFolders) {
      items.push({
        component: 'inbox2/left-nav/edit-sidebar-from-dropdown',
        pinnedFolders: this.pinnedFolders,
        pinnedInboxes: this.pinnedInboxes,
        toggleFolderPin: this.toggleFolderPin,
        toggleInboxPin: this.toggleInboxPin,
        customFolders: this.customFolders,
        onCreateCustomFolder: this.onCreateCustomFolder,
        showAiChatbotFolder: this.shouldShowAiChatbotFolder,
      });
    }
    if (!this.inbox2OptIn.canResumeOnboarding) {
      items.push({
        text: this.intl.t('inbox.inbox-list.keyboard-shortcuts'),
        icon: 'keyboard',
        onSelectItem: () => {
          this.router.transitionTo('inbox.workspace.practice-shortcuts');
        },
      });
    }
    return items.length > 0 ? [{ items }] : undefined;
  }

  @action addInboxPin(inbox: Inbox) {
    if (this.pinnedInboxes !== undefined) {
      this.inbox2Counters.updateCountFromInbox(inbox);
      this.pinnedInboxes = [...this.pinnedInboxes, inbox];
      taskFor(this.updatePinnedInboxes).perform();
      this.trackPinInbox(inbox);
    }
  }

  @action removeInboxPin(inbox: Inbox) {
    this.pinnedInboxes = this.pinnedInboxes?.filter((pinnedInbox) => pinnedInbox.id !== inbox.id);
    taskFor(this.updatePinnedInboxes).perform();
    this.trackUnPinInbox(inbox);
  }

  @action removeDefaultInboxPin(inbox: Inbox) {
    this.pinnedInboxes = this.pinnedInboxes?.filter((pinnedInbox) => pinnedInbox.id !== inbox.id);
    taskFor(this.updateDefaultPinnedInboxes).perform();
    this.trackDefaultUnPinInbox(inbox);
  }

  @action async addDefaultInboxPin(inbox: Inbox) {
    if (this.pinnedInboxes !== undefined) {
      this.pinnedInboxes = [...this.pinnedInboxes, inbox];
      taskFor(this.updateDefaultPinnedInboxes).perform();
      let inboxFromServer = await this.inboxApi.fetchInbox(inbox.category, inbox.id, true);
      this.inbox2Counters.updateCountFromInbox(inboxFromServer);
      this.trackDefaultPinInbox(inbox);
    }
  }

  @action reorderInboxes(inboxes: Inbox[], draggedInbox: Inbox) {
    let topInboxes = [
      this.yourInbox,
      this.mentionsInbox,
      this.createdByYouInbox,
      this.allInbox,
      this.unassignedInbox,
    ].filter((inbox): inbox is Inbox => !!inbox);

    let pinnedTeams = this.teamInboxes;
    let pinnedTeammates = this.adminInboxes;
    let pinnedViews = this.views;
    let pinnedAiChatbotViews = this.aiChatbotInboxes;

    if (draggedInbox instanceof Team) {
      pinnedTeams = inboxes;
    } else if (draggedInbox instanceof Admin) {
      pinnedTeammates = inboxes;
    } else if (draggedInbox instanceof View) {
      pinnedViews = inboxes;
    }

    this.pinnedInboxes = [
      ...topInboxes,
      ...pinnedTeams,
      ...pinnedTeammates,
      ...pinnedViews,
      ...pinnedAiChatbotViews,
    ];
    taskFor(this.updatePinnedInboxes).perform();
    this.trackReorderInboxes(draggedInbox);
  }

  @task({ keepLatest: true })
  *updatePinnedInboxes(): TaskGenerator<void> {
    // Remove default inboxes from the pinned inboxes list before updating the pinned inboxes as they are not tracked using visible_inbox_sidebar_item_ids
    let inboxes = this.pinnedInboxes;
    inboxes = this.pinnedInboxes?.filter(
      (i) =>
        i !== this.yourInbox &&
        i !== this.allInbox &&
        i !== this.mentionsInbox &&
        i !== this.createdByYouInbox &&
        i !== this.unassignedInbox,
    );

    let pinnedInboxesIds = inboxes?.map((inbox) =>
      inbox instanceof View ? `view:${inbox.id}` : inbox.id,
    );
    yield this.session.updateTeammate({
      visible_inbox_sidebar_item_ids: pinnedInboxesIds,
    });
  }

  @task({ keepLatest: true })
  *updateDefaultPinnedInboxes(): TaskGenerator<void> {
    let inboxes = this.pinnedInboxes;
    inboxes = this.pinnedInboxes?.filter(
      (i) =>
        i === this.yourInbox ||
        i === this.allInbox ||
        i === this.mentionsInbox ||
        i === this.createdByYouInbox ||
        i === this.unassignedInbox ||
        i === this.spamInbox,
    );
    let pinnedInboxesIds = inboxes?.map((inbox) => {
      return inbox.id;
    });
    yield this.session.updateTeammate({
      visible_inbox_sidebar_pinned_inbox_ids: pinnedInboxesIds,
    });
  }

  @action reorderPinnedFolders(pinnedFolders: PinnedFolder[], draggedFolder: PinnedFolder) {
    this.pinnedFolders = pinnedFolders;
    taskFor(this.updatePinnedFolders).perform();
    this.trackReorderPinnedFolders(draggedFolder);
  }

  @action toggleFolderPin(folder: PinnedFolder) {
    let pinned = this.pinnedFolders.find((f) => f.id === folder.id) !== undefined;

    pinned ? this.removeFolderPin(folder) : this.addFolderPin(folder);
  }

  @action toggleInboxPin(inbox: Inbox) {
    let pinned = this.pinnedInboxes?.find((i) => i.id === inbox.id) !== undefined;
    pinned ? this.removeDefaultInboxPin(inbox) : this.addDefaultInboxPin(inbox);
  }

  removeFolderPin(folder: PinnedFolder) {
    this.pinnedFolders = this.pinnedFolders.filter((f) => f.id !== folder.id);
    taskFor(this.updatePinnedFolders).perform();
    this.trackRemoveFolderPin(folder);
  }

  addFolderPin(folder: PinnedFolder) {
    this.pinnedFolders = [...this.pinnedFolders, folder];
    taskFor(this.updatePinnedFolders).perform();
    this.trackAddFolderPin(folder);
  }

  @task({ keepLatest: true })
  *updatePinnedFolders(): TaskGenerator<void> {
    let folderIds = this.pinnedFolders.map((f) => f.id);

    try {
      yield this.session.updateTeammate({ visible_inbox_sidebar_folder_ids: folderIds });
    } catch (error) {
      this.snackbar.notifyError(this.intl.t('inbox.left-nav.errors.reordering-folders'));
    }
  }

  @action handleCountersUpdatedEvent(e: CountersUpdatedEvent): void {
    if (!this.pinnedInboxes && !this.customFolders) {
      return;
    }

    let data = e.eventData;

    if (data.source === CountersUpdatedSource.Database) {
      return;
    }

    let inboxes = this.pinnedInboxes ?? [];
    if (this.customFolders) {
      let pinnedCustomFolders = this.flattenInboxFoldersToInboxList(this.customFolders);
      inboxes = inboxes.concat(pinnedCustomFolders);
    }

    let inboxCounts = parseUpdatedCount(inboxes, data);
    inboxes.forEach((inbox, index) => {
      let count = inboxCounts[index];
      if (count !== undefined) {
        this.inbox2Counters.updateCount(inbox, count);
      }
    });
  }

  private flattenInboxFoldersToInboxList(folders: InboxFolder[]): Inbox[] {
    return folders.flatMap((folder) => folder.objects.map((folder_object) => folder_object.inbox));
  }

  @action trackPinInbox(inbox: Inbox) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'pinned',
      object: 'inbox',
      selected_inbox: inbox.type,
      selected_inbox_id: inbox.id,
      view_type: inbox instanceof View ? inbox.viewSummary.systemType : undefined,
    });
  }

  @action trackDefaultPinInbox(inbox: Inbox) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'pinned',
      object: 'default_inbox',
      selected_inbox: inbox.type,
      selected_inbox_id: inbox.id,
      view_type: inbox instanceof View ? inbox.viewSummary.systemType : undefined,
    });
  }

  @action trackUnPinInbox(inbox: Inbox) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'unpinned',
      object: 'inbox',
      selected_inbox: inbox.type,
      selected_inbox_id: inbox.id,
      view_type: inbox instanceof View ? inbox.viewSummary.systemType : undefined,
    });
  }

  @action trackDefaultUnPinInbox(inbox: Inbox) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'unpinned',
      object: 'default_inbox',
      selected_inbox: inbox.type,
      selected_inbox_id: inbox.id,
      view_type: inbox instanceof View ? inbox.viewSummary.systemType : undefined,
    });
  }

  @action trackReorderInboxes(draggedInbox: Inbox) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'reordered',
      object: 'inboxes',
      inbox_type: draggedInbox.type,
    });
  }

  @action trackReorderPinnedFolders(draggedFolder: PinnedFolder) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'reorder',
      object: 'folders',
      folder_type: draggedFolder.type,
    });
  }

  @action trackRemoveFolderPin(folder: PinnedFolder) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'unpin',
      object: 'folders',
      folder_type: folder.type,
    });
  }

  @action trackAddFolderPin(folder: PinnedFolder) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'pin',
      object: 'folders',
      folder_type: folder.type,
    });
  }

  @action async createNewTeamInbox() {
    if (!this.permissionsService.currentAdminCan('can_manage_teams')) {
      await this.permissionsModal.showModal({
        permissionsLabel: this.intl.t('inbox.common.permissions-label.can-manage-teams'),
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        place: 'inbox',
        object: 'inbox_list_dropdown_team_inboxes_cta',
        section: 'inbox',
      });
      this.router.transitionTo('apps.app.settings.helpdesk.teams', this.session.workspace.id);
    }
  }

  @action async inviteTeammates() {
    if (!this.permissionsService.currentAdminCan('can_manage_teammates')) {
      await this.permissionsModal.showModal({
        permissionsLabel: this.intl.t('inbox.common.permissions-label.can-manage-teammates'),
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        place: 'inbox',
        object: 'inbox_list_dropdown_teammates_cta',
        section: 'inbox',
      });
      this.router.transitionTo(
        'apps.app.settings.workspace.teammates.invite.new',
        this.session.workspace.id,
      );
    }
  }

  @action async createViewModalOpen(customFolderId?: number) {
    if (!this.session.teammate.permissions.canManageInboxViews) {
      await this.permissionsModal.showModal({
        permissionsLabel: this.intl.t('inbox.common.permissions-label.can-manage-inbox-views'),
      });
    } else {
      if (customFolderId) {
        this.customFolderId = customFolderId;
      }
      this.creatingView = true;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        place: 'inbox',
        object: 'inbox_list_dropdown_views_cta',
        section: 'inbox',
      });
    }
  }

  @action async createViewModalClosed(args: { success: boolean; view?: ViewSummary }) {
    this.creatingView = false;
    if (this.customFolderId) {
      let inboxFolders = await this.inboxApi.fetchFolders();
      let customFolder = inboxFolders.find((folder) => folder.id === this.customFolderId);
      if (customFolder) {
        this.updateCustomFolderResource(customFolder);
        this.updateCustomFolderCounts(customFolder);
        this.customFolderId = undefined;
      }
    }
    if (args.success && args.view) {
      this.refreshPinnedViews();
      this.router.transitionTo('inbox.workspace.inbox.inbox', InboxCategory.View, args.view.id);
    }
  }

  @action async editViewModalOpen(row: ViewSummary, hidePopover: Function | null = null) {
    if (hidePopover && typeof hidePopover === 'function') {
      hidePopover();
    }
    if (!this.session.teammate.permissions.canManageInboxViews) {
      await this.permissionsModal.showModal({
        permissionsLabel: this.intl.t('inbox.common.permissions-label.can-manage-inbox-views'),
      });
    } else {
      this.editingView = row;
    }
  }

  @action editViewModalClosed(args: { success: boolean; view?: ViewSummary }) {
    this.editingView = false;
    if (args.success && args.view) {
      let activeInbox = this.inboxState.activeInbox;
      if (
        activeInbox?.type === InboxType.View &&
        activeInbox?.id.toString() === args.view.id.toString()
      ) {
        let activeView = activeInbox as View;
        activeView.viewSummary = args.view;
        this.inboxState.setInbox(activeView);
      }
      this.refreshPinnedViews();
    }
  }

  @action onCreateCustomFolder(): void {
    this.creatingCustomFolder = true;
  }

  @action onCreateCustomFolderModalClose({
    success,
    inboxFolder,
  }: {
    success: boolean;
    inboxFolder: InboxFolder;
  }): void {
    this.creatingCustomFolder = false;

    if (success) {
      let updatedCustomFolders = this.customFolders!.concat([inboxFolder]);
      this.customFoldersResource.update(updatedCustomFolders);

      this.loadPinnedInboxes();
    }
  }

  @action onEditCustomFolder(inboxFolder: InboxFolder): void {
    this.editingCustomFolder = inboxFolder;
  }

  @action onEditCustomFolderModalClose({
    success,
    inboxFolder,
  }: {
    success: boolean;
    inboxFolder: InboxFolder;
  }): void {
    this.editingCustomFolder = false;

    if (success) {
      this.updateCustomFolderResource(inboxFolder);
    }
  }

  @action async onDeleteCustomFolder(inboxFolderId: number): Promise<void> {
    try {
      await ajaxDelete(
        `/ember/inbox/inbox_folders/${inboxFolderId}?app_id=${this.session.workspace.id}`,
      );

      this.pinnedFolders = this.pinnedFolders.filter(
        (pinnedFolder) => pinnedFolder.id !== inboxFolderId,
      );

      let updatedCustomFolders = this.customFolders!.filter(
        (customFolder) => customFolder.id !== inboxFolderId,
      );

      this.customFoldersResource.update(updatedCustomFolders);

      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: 'inbox_folder',
        id: inboxFolderId,
      });
    } catch (error) {
      this.snackbar.notifyError(this.intl.t('inbox.left-nav.errors.deleting-custom-folder'));
    }
  }

  @action updateCustomFolder(inboxFolder: InboxFolder): Promise<void> {
    this.updateCustomFolderCounts(inboxFolder);
    this.updateCustomFolderResource(inboxFolder);
    return this.doUpdateCustomFolder(inboxFolder);
  }

  private updateCustomFolderCounts(inboxFolder: InboxFolder) {
    let inboxes: Inbox[] = [];

    inboxFolder.objects.forEach((inboxFolderObject) => {
      inboxes.push(inboxFolderObject.inbox);
    });
    this.inbox2Counters.updateCountsFromInboxes(inboxes);
  }

  private updateCustomFolderResource(inboxFolder: InboxFolder): void {
    let index = this.customFolders!.findIndex((customFolder) => customFolder.id === inboxFolder.id);

    let updatedCustomFolders = [...this.customFolders!];

    updatedCustomFolders[index] = inboxFolder;

    this.customFoldersResource.update(updatedCustomFolders);
  }

  private async doUpdateCustomFolder(updatedInboxFolder: InboxFolder): Promise<void> {
    try {
      return await put(
        `/ember/inbox/inbox_folders/${updatedInboxFolder.id}?app_id=${this.session.workspace.id}`,
        {
          name: updatedInboxFolder.name,
          objects: updatedInboxFolder.objects.map((inboxFolderObject) => {
            return {
              object_type: inboxFolderObject.objectType,
              object_id: inboxFolderObject.objectId,
              position: inboxFolderObject.position,
            };
          }),
        },
      );
    } catch (error) {
      this.snackbar.notifyError(this.intl.t('inbox.left-nav.errors.updating-custom-folder'));
    }
  }

  @action deleteViewModalClosed(args: { success: boolean; view?: ViewSummary }) {
    this.deletingView = false;
    if (args.success && args.view) {
      let activeInbox = this.inboxState.activeInbox;
      if (
        activeInbox?.type === InboxType.View &&
        activeInbox?.id.toString() === args.view.id.toString()
      ) {
        this.router.transitionTo('inbox.workspace.inbox.inbox', InboxCategory.Shared, 'all');
      }
      this.refreshPinnedViews();
    }
  }

  get hasClickedFinishedOnboarding(): boolean {
    return storage.get(storageKey(this.session.workspace.id)) === 'true';
  }

  @action onClickFinishedOnboarding() {
    if (!this.hasClickedFinishedOnboarding) {
      storage.set(storageKey(this.session.workspace.id), 'true');
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'resume',
      object: 'onboarding',
    });
  }

  @action
  toggleWidgetVisibility(visibility: boolean) {
    this.greatGuidanceService.setWidgetVisibility(INBOX_3DOTMENU, visibility);
  }
}

export function storageKey(workspace_id: string) {
  return `${workspace_id}:has-clicked-finished-onboarding`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::InboxList': typeof InboxList;
    'inbox2/left-nav/inbox-list': typeof InboxList;
  }
}
