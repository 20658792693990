/* import __COLOCATED_TEMPLATE__ from './attachments.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { type AttachmentList } from '@intercom/interblocks.ts';

interface Args {
  list: AttachmentList;
}

interface Signature {
  Args: Args;
}
export default class Attachments extends Component<Signature> {
  get attachments() {
    return this.args.list.attachments;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::Attachments': typeof Attachments;
    'inbox2/knowledge-base/attachments': typeof Attachments;
  }
}
