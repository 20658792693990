/* import __COLOCATED_TEMPLATE__ from './inbox-list-item-pin.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type InboxState from 'embercom/services/inbox-state';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
// @ts-ignore
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import { action } from '@ember/object';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import Team from 'embercom/objects/inbox/inboxes/team';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import Admin from 'embercom/objects/inbox/inboxes/admin';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import View from 'embercom/objects/inbox/inboxes/view';
import ViewSummary from 'embercom/objects/inbox/view-summary';
import { transformAvatarUrl } from 'embercom/objects/inbox/inboxes/avatars';
import type NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import type ThemeService from 'embercom/services/theme-service';

interface Args {
  data: any;
  hidePopover: Function;
  inboxCategory: InboxCategory;
  pinnedInboxes: Array<Inbox>;
  addPin: Function;
  removePin: Function;
  disablePinning: boolean;
  disablePinningTooltip?: string;
  rowIndex: number;
  selection: NavigableSelection;
  onEditView?: Function;
  expandFolder: () => void;
  // If the inbox is being rendered as part of a custom folder this argument will have the
  // id of the custom folder
  customFolderId?: number;
}

interface Signature {
  Args: Args;
}

export default class InboxListItemPin extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;
  @service declare themeService: ThemeService;

  get inbox() {
    let { id, name, count, icon, imageURL, predicates, systemType, isOperator } = this.args.data;

    if (this.args.inboxCategory === InboxCategory.Team) {
      return new Team(count, new TeamSummary(Number(id), name, icon, count));
    } else if (this.args.inboxCategory === InboxCategory.Admin) {
      return new Admin(
        count,
        new AdminSummary(
          Number(id),
          name,
          imageURL,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          isOperator,
        ),
      );
    } else if (this.args.inboxCategory === InboxCategory.View) {
      return new View(
        count,
        new ViewSummary(Number(id), name, icon, count, predicates, systemType),
      );
    }
    return { ...this.args.data, category: this.args.inboxCategory } as Inbox;
  }

  get avatar(): string {
    let url: string = DefaultAvatars.admins[128];

    if (this.inbox instanceof Admin) {
      url = this.inbox.avatar;
    }

    return transformAvatarUrl(url, this.themeService.darkModeEnabled);
  }

  get isPinned() {
    return this.args.pinnedInboxes.map((inbox) => inbox.id).includes(this.inbox.id.toString());
  }

  get pinIcon() {
    return this.isPinned ? 'pinned' : 'unpinned';
  }

  @action onClick() {
    this.inboxState.switchInbox(this.inbox, this.args.customFolderId);
  }

  @action async togglePin() {
    if (this.isPinned) {
      this.args.removePin(this.inbox);
    } else {
      this.args.addPin(this.inbox);
      this.args.expandFolder();
    }
  }

  @action trackHover() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'hover',
      object: 'inbox',
      inbox_type: this.inbox.type,
    });
  }

  @action trackOpenInbox() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'inbox',
      place: 'inbox',
      section: 'inbox_list_edit_menu',
      selected_inbox: this.inbox?.type,
      selected_inbox_id: this.inbox?.id,
      view_type: this.inbox instanceof View ? this.inbox.viewSummary.systemType : undefined,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::InboxListItemPin': typeof InboxListItemPin;
    'inbox2/left-nav/inbox-list-item-pin': typeof InboxListItemPin;
  }
}
