/* import __COLOCATED_TEMPLATE__ from './web-installation-javascript-custom-data-snippet-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import baseCustomDataSnippetComponent from 'embercom/components/installation-instructions/base-custom-data-snippet-component';

export default baseCustomDataSnippetComponent.extend({
  language: 'unknown',

  planName: reads('example.planName'),
  monthlySpend: reads('example.monthlySpend'),
  upgradedAt: reads('example.upgradedAt'),
  hasPurchased: reads('example.hasPurchased'),
  customDomain: reads('example.customDomain'),

  example: computed('language', function () {
    let language = this.language;
    let languageIsDefined = Object.keys(this.examples).includes(language);

    if (languageIsDefined) {
      return this.get(`examples.${language}`);
    } else {
      return this.get('examples.unknown');
    }
  }),

  examples: computed(function () {
    return {
      unknown: {
        planName: 'basic',
        monthlySpend: 49.99,
        upgradedAt: 1350466020,
        hasPurchased: true,
        customDomain: 'null',
      },
      rails: {
        planName: '<%= current_user.plan_name %>',
        monthlySpend: '<%= current_user.montly_spend %>',
        upgradedAt: '<%= current_user.upgraded_at.to_i %>',
        hasPurchased: '<%= current_user.has_purchased? %>',
        customDomain: '<%= current_user.custom_domain %>',
      },
      django: {
        planName: '{{ request.user.plan_name|escapejs }}',
        monthlySpend: '{{ request.user.monthly_spend }}',
        upgradedAt: '{{ request.user.updated_at|date:"U" }}',
        hasPurchased: '{{ request.user.has_purchased|yesno:"true,false" }}',
        customDomain: '{% firstof request.user.custom_domain "null" %}',
      },
      php: {
        planName: '<?php echo json_encode($user->planName); ?>',
        monthlySpend: '<?php echo json_encode($user->monthlySpend); ?>',
        upgradedAt: '<?php echo json_encode($user->upgradedAt->format("U")); ?>',
        hasPurchased: '<?php echo json_encode($user->hasPurchased); ?>',
        customDomain: '<?php echo json_encode($user->customDomain); ?>',
      },
    };
  }),

  setLanguage: action(function (language) {
    this.set('language', language);
  }),
});
