/* import __COLOCATED_TEMPLATE__ from './check-installation.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { task } from 'ember-concurrency-decorators';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type PingDomain from 'embercom/models/ping-domain';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import type MessengerInstallation from 'embercom/services/messenger-installation';

interface Args {
  openUserHashChecker: () => void;
  profilesForLoggedInUserEnabled: boolean;
}

interface Signature {
  Args: Args;
}

export default class CheckInstallation extends Component<Signature> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare messengerInstallation: MessengerInstallation;

  @tracked errorMessage: string | null = null;
  @tracked pingDomains: PingDomain[] = [];
  @tracked openSectionId: string | null = null;
  @tracked pingDomainsErrorsCount: Record<string, number> = {};
  @tracked pingErrorsLoaded = false;

  constructor(owner: any, args: Args) {
    super(owner, args);
  }

  get displayUserApiDisabledError() {
    return this.app.isWebMessengerDisabled;
  }

  get app() {
    return this.appService.app;
  }

  get isFeaturePingErrorsFlagEnabled() {
    return this.app.canUseFeature('team-app-security-ping-errors');
  }

  get showPingDomains() {
    return this.pingDomains.length > 0;
  }

  @task({ drop: true })
  *checkPings(): TaskGenerator<void> {
    this.errorMessage = null;
    if (this.displayUserApiDisabledError) {
      return;
    }
    try {
      this.pingDomains = yield this.store.query('ping-domain', { ignore_anonymous: true });
      if (this.pingDomains.length === 0) {
        this.errorMessage = this.intl.t(
          'components.guide.start-screen.identity-verification-ping-domains.data-not-recieved',
        );
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'check_idv_installation',
        place: 'installation-new',
        section: 'settings',
      });
    } catch (error) {
      this.errorMessage = error.message;
    }
  }

  @action
  async enableUserTrafficApi() {
    await taskFor(this.messengerInstallation.togglePlatformApiAvailability).perform('web', false);
    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.check-installation-step.user-traffic-enabled',
      ),
    );
  }

  accordionIdForDomain(domain: string) {
    return `section_domain_${domain.replace(/[^a-zA-Z0-9]/g, '_')}`;
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action
  errorCountForPingDomain(domain: string) {
    return this.pingDomainsErrorsCount[domain];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::IdvSetup::CheckInstallation': typeof CheckInstallation;
    'installation-new/web/idv-setup/check-installation': typeof CheckInstallation;
  }
}
