/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import type IntlService from 'ember-intl/services/intl';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import {
  INSTALLATION_METHOD,
  INSTALLATION_TYPE,
} from 'embercom/components/installation-new/constants';
import type RouterService from '@ember/routing/router-service';

interface Args {
  selectedAccordion: string;
  onAccordionChange: (accordion: string) => void;
}

interface Signature {
  Args: Args;
  Element: any;
}

export default class SetupComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare messengerInstallation: MessengerInstallation;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  @tracked showUserHashCheckerModal = false;

  @tracked selectedInstallationMethod = this.installationMethods[0].id;

  @tracked selectedInstallationType: INSTALLATION_TYPE = INSTALLATION_TYPE.LOGGED_IN_USERS;
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.app.api_secret) {
      // this will only work if the teammate has permissions, otherwise it will set the secret to null
      this.app.fetchAPISecret.perform();
    }
  }

  get app() {
    return this.appService.app;
  }

  get isMessengerInstalled() {
    return this.messengerInstallation.isInstalledForWeb;
  }

  get isMessengerAlreadyInstalledForVisitorsOnly() {
    return (
      this.messengerInstallation.isInstalledForVisitors &&
      !this.messengerInstallation.isInstalledForUsers
    );
  }

  get isMessengerAlreadyInstalledForUsers() {
    return this.messengerInstallation.isInstalledForUsers;
  }

  get messengerInstallationState() {
    if (this.isMessengerInstalled && this.isMessengerAlreadyInstalledForUsers) {
      return 'installed-for-logged-in-users';
    } else if (this.isMessengerAlreadyInstalledForVisitorsOnly) {
      return 'installed-for-visitors-only';
    }
    return 'not-installed';
  }

  get messengerInstallationStatus() {
    let messengerInstallationState = this.messengerInstallationState;
    let text = '';
    let color = '';

    switch (messengerInstallationState) {
      case 'installed-for-logged-in-users':
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-messenger.installed',
        );
        color = 'green';
        break;

      case 'installed-for-visitors-only':
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-messenger.installed-for-visitors',
        );
        color = 'yellow';
        break;

      case 'not-installed':
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-messenger.not-installed',
        );
        color = 'gray';
        break;
    }

    return {
      text,
      color,
    };
  }

  get messengerInstallationCtaLabel() {
    if (this.idvState === 'not-required') {
      return this.intl.t(
        'apps.app.settings.installation.installation.new.installation-status.done-label',
      );
    }

    return this.intl.t(
      'apps.app.settings.installation.installation.new.installation-status.next-button-label',
    );
  }

  get isIdvConfigured() {
    return this.messengerInstallation.isIdvConfiguredForWeb;
  }

  get idvState() {
    if (this.isIdvConfigured) {
      return 'secured';
    } else if (this.isMessengerAlreadyInstalledForUsers) {
      return 'recommended';
    } else if (
      this.isMessengerAlreadyInstalledForVisitorsOnly ||
      this.selectedInstallationType === INSTALLATION_TYPE.VISITORS
    ) {
      return 'not-required';
    }
    return 'not-configured';
  }

  get idvStateIsSecured() {
    return this.idvState === 'secured';
  }

  get showIdvAccordion() {
    return this.idvStateIsSecured || !this.canUseJwtSecuritySettings;
  }

  get idvSetupInstallationStatus() {
    let idvState = this.idvState;
    let text = '';
    let color = '';

    switch (idvState) {
      case 'secured':
        text = this.intl.t('apps.app.settings.installation.installation.new.web.setup-idv.secured');
        color = 'green';
        break;

      case 'recommended':
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-idv.recommended',
        );
        color = 'yellow';
        break;

      case 'not-required':
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-idv.not-required',
        );
        color = 'gray';
        break;

      case 'not-configured':
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-idv.not-configured',
        );
        color = 'gray';
        break;
    }

    return {
      text,
      color,
    };
  }

  get installationMethods() {
    let baseInstallationMethods = [
      {
        id: INSTALLATION_METHOD.CODE_SNIPPET,
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.methods.code-snippet-label',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/icon-code.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.title',
        ),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.sub-title',
        ),
      },
      {
        id: INSTALLATION_METHOD.REACT,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.react-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/react.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.react.title',
        ),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.react.sub-title',
        ),
      },
      {
        id: INSTALLATION_METHOD.ANGULAR,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.angular-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/angular.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.angular.title',
        ),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.angular.sub-title',
        ),
      },
      {
        id: INSTALLATION_METHOD.VUE,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.vue-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/vue.svg'),
        instructionsTitle: this.intl.t('apps.app.settings.installation.installation.new.vue.title'),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.vue.sub-title',
        ),
      },
      {
        id: INSTALLATION_METHOD.EMBER,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.ember-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/ember.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.ember.title',
        ),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.ember.sub-title',
        ),
      },
      ...(this.app.isStandaloneApp
        ? []
        : [
            {
              id: INSTALLATION_METHOD.WORDPRESS,
              label: this.intl.t(
                'apps.app.settings.installation.installation.new.methods.wordpress-label',
              ),
              iconUrl: assetUrl(
                '/assets/images/settings/messenger-installation-new/icon-wordpress.svg',
              ),
              instructionsTitle: this.intl.t(
                'apps.app.settings.installation.installation.new.wordpress.title',
              ),
            },
            {
              id: INSTALLATION_METHOD.GOOGLE_TAG_MANAGER,
              label: this.intl.t(
                'apps.app.settings.installation.installation.new.methods.google-tag-manager-label',
              ),
              iconUrl: assetUrl(
                '/assets/images/settings/messenger-installation-new/icon-googletagmanager.svg',
              ),
              instructionsTitle: this.intl.t(
                'apps.app.settings.installation.installation.new.google-tag-manager.title',
              ),
            },
          ]),
      {
        id: INSTALLATION_METHOD.SHOPIFY,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.shopify-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/icon-shopify.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.shopify.title',
        ),
      },
      {
        id: INSTALLATION_METHOD.SEGMENT,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.segment-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/icon-segment.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.segment.title',
        ),
      },
    ];

    return baseInstallationMethods;
  }

  get profilesForLoggedInUserEnabled(): boolean {
    return !this.app.is_web_messenger_disabled;
  }

  @action
  setSelectedInstallationType(installationType: INSTALLATION_TYPE) {
    this.selectedInstallationType = installationType;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'installation_type',
      metadata: {
        item: installationType.replaceAll('-', '_'),
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action
  onMessengerInstallationCtaClicked() {
    if (this.idvState === 'not-required') {
      // set to empty string so both tabs close
      this.args.onAccordionChange('');
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'done_visitor_installation_step',
        place: 'installation-new',
        section: 'settings',
      });
    } else {
      this.args.onAccordionChange('web-idv-setup');
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'next_step',
        place: 'installation-new',
        section: 'settings',
      });
    }
  }

  @action
  goToStep(step: string) {
    this.args.onAccordionChange(step);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'next_step',
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action openUserHashChecker() {
    this.showUserHashCheckerModal = true;
  }

  @action closeUserHashChecker() {
    this.showUserHashCheckerModal = false;
  }

  @action onSelectInstallationMethod(installationMethod: INSTALLATION_METHOD) {
    this.selectedInstallationMethod = installationMethod;
  }

  get canUseJwtSecuritySettings() {
    return this.app.canUseJwtSecuritySettings;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::Setup': typeof SetupComponent;
    'installation-new/web/setup': typeof SetupComponent;
  }
}
