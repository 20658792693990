/* import __COLOCATED_TEMPLATE__ from './folders.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {}

interface Signature {
  Args: Args;
}

const KnowledgeBaseFoldersSkeleton = templateOnlyComponent<Signature>();
export default KnowledgeBaseFoldersSkeleton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::LoadingSkeletons::Folders': typeof KnowledgeBaseFoldersSkeleton;
  }
}
