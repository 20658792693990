/* import __COLOCATED_TEMPLATE__ from './web-installation-snippet-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { reads, and, equal } from '@ember/object/computed';
import Component from '@ember/component';
import {
  applyFunction,
  containsByProperty,
  selectFromObject,
  ternary,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';

const UNKNOWN_HMAC_METHOD_TEXT = '"INSERT_HMAC_VALUE_HERE"';

export default Component.extend({
  unknownHmacMethodPlaceholderText: UNKNOWN_HMAC_METHOD_TEXT,
  language: null,
  installationIsAnonymous: true,
  installationHasIdentityVerified: false,
  installationHasIdentityVerifiedByUserID: false,
  installationIsSinglePageApp: false,
  appIdCode: null,
  appApiSecret: null,
  unknownExampleKey: 'unknown',

  identifierVerifiedBy: ternary('installationHasIdentityVerifiedByUserID', 'id', 'email'),

  userID: reads('example.userID'),
  name: reads('example.name'),
  email: reads('example.email'),
  createdAt: reads('example.createdAt'),
  userHash: reads('example.userHash'),
  placeholderUserHash: false,
  noPlaceholderUserHash: equal('placeholderUserHash', false),

  installationHasIdentityVerifiedAndExampleIsDjango: and(
    'installationHasIdentityVerified',
    'exampleIsDjango',
    'noPlaceholderUserHash',
  ),
  exampleIsDjango: equal('language', 'django'),

  example: selectFromObject('examples', 'exampleKeyForLanguage'),
  exampleKeyForLanguage: ternaryToProperty('languageHasExample', 'language', 'unknownExampleKey'),
  languageHasExample: containsByProperty('exampleKeys', 'language'),
  exampleKeys: applyFunction(Object.keys, 'examples'),

  examples: computed('appApiSecret', 'identifierVerifiedBy', 'placeholderUserHash', function () {
    let appApiSecret = this.appApiSecret;
    let identifierVerifiedBy = this.identifierVerifiedBy;
    let unknownHmacMethodPlaceholderText = this.unknownHmacMethodPlaceholderText;
    let showPlaceholderUserHash = this.placeholderUserHash;

    return {
      unknown: {
        userID: '"123"',
        name: '"Jane Doe"',
        email: '"customer@example.com"',
        createdAt: '"1312182000"',
        userHash: unknownHmacMethodPlaceholderText,
      },
      rails: {
        userID: '"<%= current_user.id %>"',
        name: '"<%= current_user.name %>"',
        email: '"<%= current_user.email %>"',
        createdAt: '"<%= current_user.created_at.to_i %>"',
        userHash: showPlaceholderUserHash
          ? `'<%= ${unknownHmacMethodPlaceholderText} %>'`
          : `"<%=
      OpenSSL::HMAC.hexdigest(
        'sha256',
        '${appApiSecret}',
        current_user.${identifierVerifiedBy}
      )
    %>"`,
      },
      django: {
        userID: '"{{ request.user.id|escapejs }}"',
        name: '"{{ request.user.name|escapejs }}"',
        email: '"{{ request.user.email|escapejs }}"',
        createdAt: '"{{ request.user.date_joined|date:"U" }}"',
        userHash: showPlaceholderUserHash
          ? `'{{ ${unknownHmacMethodPlaceholderText} }}'`
          : `"{{
      hmac.new(
        b'${appApiSecret}',
        bytes(request.user.${identifierVerifiedBy}, encoding='utf-8'),
        digestmod=hashlib.sha256
      ).hexdigest()
    }}"`,
      },
      php: {
        userID: '<?php echo json_encode($current_user->id) ?>',
        name: '<?php echo json_encode($current_user->name) ?>',
        email: '<?php echo json_encode($current_user->email) ?>',
        createdAt: '"<?php echo strtotime($current_user->created_at) ?>"',
        userHash: showPlaceholderUserHash
          ? `<?php echo ${unknownHmacMethodPlaceholderText} ?>`
          : `"<?php
      echo hash_hmac(
        'sha256',
        $user->${identifierVerifiedBy},
        '${appApiSecret}'
      );
    ?>"`,
      },
      node: {
        userID: 'user.id',
        name: 'user.name',
        email: 'user.email',
        createdAt: 'user.createdAt',
        userHash: unknownHmacMethodPlaceholderText,
      },
      javascript: {
        userID: 'user.id',
        name: 'user.name',
        email: 'user.email',
        createdAt: 'user.createdAt',
        userHash: unknownHmacMethodPlaceholderText,
      },
    };
  }),
});
