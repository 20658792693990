/* import __COLOCATED_TEMPLATE__ from './title-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  title?: string;
  onSelect: (title?: string) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SearchFiltersTitleSelector extends Component<Signature> {
  @service declare intl: IntlService;
  @tracked title? = this.args.title || '';

  @action clear() {
    this.title = '';
    this.args.onSelect('');
  }

  @action onSelect() {
    return this.args.onSelect(this.title);
  }

  @action updateTitle(newTitle: string) {
    this.title = newTitle;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::TitleSelector': typeof SearchFiltersTitleSelector;
  }
}
