/* import __COLOCATED_TEMPLATE__ from './search-input.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  searchQuery: string;
  onSearchQueryChange: (searchQuery: string) => void;
  clearSearchQuery: () => void;
}

interface Signature {
  Args: Args;
}

const KnowledgeBaseSearchInput = templateOnlyComponent<Signature>();
export default KnowledgeBaseSearchInput;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::SearchInput': typeof KnowledgeBaseSearchInput;
  }
}
