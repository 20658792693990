/* import __COLOCATED_TEMPLATE__ from './ticket-fields.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { type TicketTypeResource } from '../ticket-type-resource';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { action } from '@ember/object';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { tracked } from '@glimmer/tracking';
import type Brand from 'embercom/models/brand';
import type IntlService from 'ember-intl/services/intl';
import type Inbox2BrandService from 'embercom/services/inbox2-brand-service';
interface Args {
  newConversation: NewConversation;
  createTicket: any;
  isTicketRecipientMissing: boolean;
  ticketTypeResource: TicketTypeResource;
  isRecipientRequired: boolean;
  selectedUserCompanyId: string | undefined;
  brands?: Brand[];
  selectedBrandId?: number | null;
  onSelectBrand?: ((brandId: number) => void) | null | undefined;
}

interface Signature {
  Args: Args;
}

export default class TicketFields extends Component<Signature> {
  @service declare session: Session;
  @service declare router: Router;
  @service declare inbox2BrandService: Inbox2BrandService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked selectedBrandName = this.defaultBrandName;

  private DEFAULT_BRAND_ID = -1;

  get defaultBrandName() {
    return this.session.workspace.name;
  }

  get showTicketRecipientMissingError() {
    return this.args.createTicket.performCount > 0 && this.args.isTicketRecipientMissing;
  }

  get userCompanies(): { text: string | undefined; value: string }[] {
    if (!this.args.newConversation?.userSummary?.companies) {
      return [];
    } else {
      return this.args.newConversation.userSummary.companies.map((company) => ({
        text: company.name,
        value: company.id,
      }));
    }
  }

  get areAttributesVisibleToUser() {
    let category = this.args.ticketTypeResource.selectedTicketType?.category;
    return category && category === TicketCategory.Request;
  }

  get isBrandPickerVisible() {
    return (
      this.args.brands &&
      this.args.brands.length > 1 &&
      this.inbox2BrandService.isAppOnRightMultibrandPlan &&
      this.session.workspace.isFeatureEnabled('multibrand-tickets')
    );
  }

  get selectedCompanyName() {
    return this.userCompanies.find((company) => company.value === this.args.selectedUserCompanyId)
      ?.text;
  }

  get brandsList() {
    let defaultBrandOption = {
      text: this.session.workspace.name,
      value: this.DEFAULT_BRAND_ID,
    };

    return [
      defaultBrandOption,
      ...(this.customBrands || [])
        .map((brand: Brand) => ({
          text: brand.name,
          value: brand.id,
        }))
        .filter((brand) => typeof brand.value === 'number')
        .sort((a: any, b: any) => {
          return a.text.localeCompare(b.text);
        }),
    ];
  }

  get customBrands() {
    if (!this.args.brands) {
      return [];
    }
    return this.args.brands.filter((brand) => !brand.isDefault);
  }

  @action
  setSelectedBrandName(brandId: number | null | undefined) {
    if (brandId === this.DEFAULT_BRAND_ID && this.args.brands) {
      this.selectedBrandName = this.args.brands[0].name;
    } else {
      // @ts-ignore
      let foundBrand = this.args.brands.find((brand) => brand.id === brandId);
      this.selectedBrandName =
        foundBrand?.name ?? this.intl.t('inbox.new-conversation.select-brand');
    }
  }

  get showUserCompanyDropdown() {
    return (
      this.args.ticketTypeResource.selectedTicketType &&
      [TicketCategory.Request, TicketCategory.Task].includes(
        this.args.ticketTypeResource.selectedTicketType.category,
      ) &&
      this.args.newConversation.userSummary &&
      this.userCompanies.length > 0
    );
  }

  @action
  onCancel() {
    this.router.transitionTo('inbox.workspace.inbox.inbox', InboxCategory.Shared, InboxType.All);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NewConversation::TicketFields': typeof TicketFields;
    'inbox2/new-conversation/ticket-fields': typeof TicketFields;
  }
}
