/* import __COLOCATED_TEMPLATE__ from './code-block.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import ClipboardJS from 'clipboard';
import { inject as service } from '@ember/service';
import { htmlUnescape } from 'embercom/lib/html-unescape';
import { captureException } from 'embercom/lib/sentry';
import type IntlService from 'ember-intl/services/intl';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { modifier } from 'ember-modifier';
import { PROGRAMMING_LANGUAGES } from 'embercom/components/installation-new/constants';

// https://github.com/sindresorhus/strip-indent/blob/master/index.js
const unindent = (str: string) => {
  let match = str.match(/^[ \t]*(?=\S)/gm);

  if (!match) {
    return str;
  }

  let indent = match.reduce((r, a) => Math.min(r, a.length), Infinity);

  let re = new RegExp(`^[ \\t]{${indent}}`, 'gm');

  return indent > 0 ? str.replace(re, '') : str;
};

const stripHTMLComments = (str: string) => {
  return str.replace(/<!---->/g, '');
};

const trim = (str: string) => {
  return str.replace(/^\s+|\s+$/g, '');
};

const lineCount = (str: string) => {
  return str.split('\n').length;
};

interface Args {
  language: string;
  containerId: string;
  userIdentifier?: string;
  appType?: string;
  apiSecret?: string;
  onCopyCode?: () => void;
  disabled?: boolean;
  hideLineNumbers?: boolean;
  title?: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class CodeBlock extends Component<Signature> {
  @service declare notificationsService: any;
  @service declare appService: $TSFixMe;
  @service intl!: IntlService;

  clipboard: ClipboardJS | null = null;

  highlightSupportedLanguages: string[] = [
    PROGRAMMING_LANGUAGES.JAVASCRIPT,
    PROGRAMMING_LANGUAGES.DJANGO,
    PROGRAMMING_LANGUAGES.PHP,
    PROGRAMMING_LANGUAGES.HTML,
    PROGRAMMING_LANGUAGES.SWIFT,
    PROGRAMMING_LANGUAGES.OBJECTIVE_C,
    PROGRAMMING_LANGUAGES.JAVA,

    // TODO: Current highlight.js doesn't support these languages
    // PROGRAMMING_LANGUAGES.GROOVY,
    // PROGRAMMING_LANGUAGES.KOTLIN,
  ];

  codeBlockContainer = modifier(
    (_: Element, [_apiSecret]: [string | undefined]) => {
      this.onRender();
    },
    { eager: false },
  );

  onRender() {
    let copyCodeButton = document.querySelector(`.${this.args.containerId}-clipboard-button`);
    if (copyCodeButton) {
      if (!this.clipboard) {
        this.clipboard = new ClipboardJS(copyCodeButton);
        this.clipboard.on('success', () => {
          this.args.onCopyCode?.();
          this.notificationsService.notifyConfirmation(
            this.intl.t('apps.app.settings.installation.installation.new.code-copied'),
          );
        });
        this.clipboard.on('error', (err) => {
          console.error(err);
          this.notificationsService.notifyWarning(
            this.intl.t('apps.app.settings.installation.installation.new.code-copying-error'),
          );
        });
      }

      this.onLoadOrUpdateLanguage();
    }
  }

  onLoadOrUpdateLanguage() {
    let currentView = document.querySelector(`#${this.args.containerId}`);
    let hiddenView = currentView?.querySelector('.code-block__hidden-output');
    let hiddenViewSubview = hiddenView?.querySelector('.ember-view');
    let content;

    if (hiddenViewSubview) {
      content = hiddenViewSubview;
    } else {
      content = hiddenView;
    }

    if (content) {
      let code = htmlUnescape(content.innerHTML);
      code = unindent(code);
      code = stripHTMLComments(code);
      code = trim(code);

      let lineNumbers = [];
      for (let i = 1; i <= lineCount(code); i++) {
        lineNumbers.push(i);
      }

      let highlightedCode;
      if (this.args.language && this.highlightSupportedLanguages.includes(this.args.language)) {
        // @ts-ignore
        highlightedCode = hljs.highlight(this.args.language, code).value;
      } else {
        // @ts-ignore
        highlightedCode = hljs.highlightAuto(code).value;
      }

      let codeHighlightView = currentView?.querySelector('#code-highlight');
      if (codeHighlightView) {
        if (this.args.userIdentifier) {
          codeHighlightView.setAttribute('data-user-identifier', this.args.userIdentifier);
        }
        if (this.args.appType) {
          codeHighlightView.setAttribute('data-app-type', this.args.appType);
        }
        codeHighlightView.innerHTML = sanitizeHtml(highlightedCode);
      }

      let lineNumbersView = currentView?.querySelector('.code-block__line-numbers');
      if (lineNumbersView) {
        lineNumbersView.innerHTML = sanitizeHtml(lineNumbers.join('\n'));
      }

      let copyCodeButton = document.querySelector(`.${this.args.containerId}-clipboard-button`);
      if (copyCodeButton) {
        copyCodeButton.setAttribute('data-clipboard-text', code);
      }
    }
  }

  willDestroy() {
    super.willDestroy();
    try {
      this.clipboard?.destroy();
    } catch (e) {
      captureException(e, {
        fingerprint: ['clipboard.destroy', 'code-component'],
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::CodeBlock': typeof CodeBlock;
    'installation-new/code-block': typeof CodeBlock;
  }
}
