/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import { capitalize } from '@ember/string';
export default Component.extend({
  cdas: null,

  latestDate: computed('cdas', function () {
    return this.cdas.reduce((cda1, cda2) => {
      return new Date(cda1.created_at) > new Date(cda2.created_at) ? cda1 : cda2;
    }).created_at;
  }),

  latestCdaList: computed('cdas', 'latestDate', 'language', function () {
    let language = this.language === 'unknown' ? 'javascript' : this.language;
    let latestCdas = this.cdas.filterBy('created_at', this.latestDate);

    return this._convertCdasToCode(latestCdas, language);
  }),

  previousCdaList: computed('cdas', 'latestDate', 'language', function () {
    let language = this.language === 'unknown' ? 'javascript' : this.language;
    let previousCdas = this.cdas.rejectBy('created_at', this.latestDate);

    return this._convertCdasToCode(previousCdas, language);
  }),

  _convertCdasToCode(cdaList, language) {
    return cdaList.map((cda) => {
      return {
        key: cda['name'],
        value: this._languageValue(cda, language),
      };
    });
  },

  _languageValue(cda, language) {
    let type = cda['data_type'] || capitalize(cda['type']);
    let comment = this._formatType(type);

    return {
      javascript: `// ${comment}`,
      singlePage: `// ${comment}`,
      rails: `# ${comment}`,
      objectivec: `// ${comment}`,
      swift: `// ${comment}`,
      android: `// ${comment}`,
    }[language];
  },

  _formatType(type = '') {
    return type === 'Float' || type === 'Fixnum' ? 'Number' : type;
  },
});
