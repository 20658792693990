/* import __COLOCATED_TEMPLATE__ from './conversation-card.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type InboxState from 'embercom/services/inbox-state';
import type IntlService from 'embercom/services/intl';
import { InboxSortOption } from 'embercom/models/data/inbox/inbox-filters';
import type Session from 'embercom/services/session';
import type UserApi from 'embercom/services/user-api';
import type ConversationOptions from 'embercom/services/inbox2-conversation-options-service';
import type Conversation from 'embercom/objects/inbox/conversation';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type ConversationResolveAndCloseService from 'embercom/services/conversation-resolve-and-close-service';
import { type SortParams } from 'embercom/services/inbox-api';

interface Arguments {
  cardRoute: string;
  cardRouteModels: unknown[];
  cardIndex: number;
  conversation: ConversationSummary | Conversation;
  isSelected: boolean;
  conversationListIsInFocus: boolean;
  toggleSelection: (event?: Event) => unknown;
  selectedSortOption?: SortParams;
  skipResolvedCheck?: boolean;
  onClose?: () => unknown;
  isHighlighted?: boolean;
  showCheckbox?: boolean;
  canBeFocused?: boolean;
}

interface Signature {
  Args: Arguments;
  Element: HTMLAnchorElement;
}

export default class ConversationCard extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare userApi: UserApi;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare inbox2ConversationOptionsService: ConversationOptions;
  @service declare intercomEventService: any;
  @service declare ticketStateService: TicketStateService;
  @service declare conversationResolveAndCloseService: ConversationResolveAndCloseService;

  @tracked isHovering = false;
  @tracked isFocused = false;
  @tracked isLinkDisabled = false;
  @tracked showSlaConfirmationModal = false;
  @tracked displayResolveAndCloseModal = false;

  inboxSortOption = InboxSortOption;

  get workspaceID() {
    return this.session.workspace.id;
  }

  get emptyArray() {
    return [];
  }

  get isRestricted(): boolean {
    return this.args.conversation.redacted;
  }

  get currentTicketCustomState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(
      this.args.conversation?.ticketCustomStateId,
    );
  }

  @action async reopen() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      section: 'conversation_card_button',
      object: 'conversation',
    });

    return this.inboxState.openConversation(this.args.conversation);
  }

  @action async onMaybeClose(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      section: 'conversation_card_button',
      object: 'conversation',
    });

    await this.onClose();
  }

  @action async onMaybeCloseFromOptions() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      section: 'conversation_list',
      object: 'conversation',
    });

    await this.onClose();
  }

  private async onClose() {
    this.conversationResolveAndCloseService.startResolveAndCloseProcess(this.args.conversation);
  }

  get isActiveConversation(): boolean {
    return this.args.conversation?.id === this.inboxState.activeConversationId;
  }

  get isHighlighted() {
    if (this.args.isHighlighted !== undefined) {
      return this.args.isHighlighted;
    }
    return this.isActiveConversation || this.args.isSelected;
  }

  private companyNameToDisplay(user: UserSummary): string | null {
    if (!this.args.conversation.isTicket) {
      return user.company?.name ?? null;
    }

    if (user.companies === undefined || user.companies.length === 0) {
      return null;
    }

    let associatedCompanyId = this.args.conversation.companyId;
    if (associatedCompanyId === null || associatedCompanyId === undefined) {
      return null;
    }

    let associatedCompany = user.companies.find(
      (company: CompanySummary) => company.id === associatedCompanyId,
    );

    return associatedCompany?.name || null;
  }

  get heading(): string {
    let { conversation: conversationSummary } = this.args;
    let user = conversationSummary.firstParticipant;
    if (conversationSummary.hasMultipleParticipants) {
      return this.intl.t('inbox.conversation-list.conversation-participants-summary', {
        firstParticipant: user.displayAs,
        remainingCount: conversationSummary.participantSummaries.length - 1,
      });
    }

    let { firstName, emailUsername } = user;
    let companyNameToDisplay = this.companyNameToDisplay(user);

    if (firstName && companyNameToDisplay) {
      return `${user.firstName} · ${companyNameToDisplay}`;
    } else if (emailUsername && companyNameToDisplay) {
      return `${user.emailUsername} · ${companyNameToDisplay}`;
    }

    return user.displayAs;
  }

  get ticketHeading(): string {
    let { conversation } = this.args;
    return typeof conversation.ticketTitle === 'string' ? conversation.ticketTitle : this.heading;
  }

  get timestamp(): Date | undefined {
    if (this.args.conversation.lastAdminMentionedPart) {
      return this.args.conversation.lastAdminMentionedPart.createdAt;
    }

    // If no sort option is selected, default to last activity
    if (!this.args.selectedSortOption) {
      return this.args.conversation.lastUpdated;
    }

    let sortField = this.args.selectedSortOption.sort_field;

    // Rule: If sort is based on Date & time attribute, use that attribute data to calculate timestamp
    switch (sortField) {
      case 'conversation_started_at':
        // Started sort option - use creation date
        return this.args.conversation.createdAt;

      case 'waiting_since':
        // Waiting sort option - use waiting since time
        return this.args.conversation.waitingSince;

      case 'ticket_created_at':
        // Submitted sort option - use ticket creation date
        // This will be undefined for non-tickets, which is handled in the template with a dash
        return this.args.conversation.createdAt;

      default:
        // For all other sort options (including sorting_updated_at), use last activity
        return this.args.conversation.lastUpdated;
    }
  }

  get showCheckBox() {
    if (this.args.showCheckbox !== undefined) {
      return this.args.showCheckbox;
    }
    return this.canBeModified && (this.args.isSelected || this.isHovering);
  }

  get canBeModified() {
    return !this.isRestricted && !this.session.showLightInbox;
  }

  get dividerStyles() {
    let activeClass = 'active';
    let transparentClass = 'bg-transparent';
    let isElementActive = this.isHighlighted || this.isHovering || this.isFocused;

    if (this.args.cardIndex === 0) {
      return isElementActive ? `${activeClass} ${transparentClass}` : transparentClass;
    }

    if (isElementActive) {
      return `${activeClass} ${transparentClass}`;
    }

    return 'bg-neutral-container ';
  }

  @action toggleSelection(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.args.toggleSelection(event);
  }

  @action async removeSlaAndClose(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    let nextBreachTime = this.args.conversation.nextBreachTime;

    this.showSlaConfirmationModal = false;
    this.args.conversation.nextBreachTime = undefined;
    try {
      await this.inbox2ConversationOptionsService.removeSla(this.args.conversation);
    } catch {
      this.args.conversation.nextBreachTime = nextBreachTime;
    }
  }

  @action onRemoveSla() {
    this.showSlaConfirmationModal = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationCard': typeof ConversationCard;
    'inbox2/left-nav/conversation-card': typeof ConversationCard;
  }
}
