/* import __COLOCATED_TEMPLATE__ from './editable-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { type TicketDescriptor } from 'embercom/objects/inbox/ticket';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { DEFAULT_TICKET_TYPE_ATTRIBUTES } from 'embercom/lib/inbox/constants';

interface Args {
  attribute: TicketAttributeSummary;
  descriptor?: TicketDescriptor;
  isCreatingTicket: boolean;
  showError?: boolean;
  showErrorOnFocus?: boolean;
  onUpdate?: (attribute: TicketAttributeSummary, descriptor?: TicketDescriptor) => void;
  autofocus?: boolean;
  isReadOnly?: boolean;
  showPlaceholder?: boolean;
  listOptions?: Record<string, string[]>;
  brandsList?: any;
}

interface Signature {
  Args: Args;
}

export default class EditableAttribute extends Component<Signature> {
  @service declare session: Session;

  @trackedReset('args.attribute') attribute =
    this.args.attribute ?? new TicketAttributeSummary(undefined, this.descriptor);

  get descriptor() {
    return this.args.descriptor || this.args.attribute.descriptor;
  }

  get isTitle() {
    if (!this.args.attribute) {
      return false;
    }
    return this.args.attribute?.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE;
  }

  get isReadOnly() {
    return this.session.showLightInbox || this.args.isReadOnly;
  }

  get autofocus() {
    return !!this.args.autofocus;
  }

  get showRichTextDescription() {
    return (
      this.descriptor?.isBuiltIn &&
      this.descriptor?.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION
    );
  }

  @action
  onSubmit() {
    this.attribute.normalizeValue();

    if (this.args.onUpdate) {
      this.args.onUpdate(this.attribute, this.descriptor);
    }
  }

  @action
  onSelectItem(value: any) {
    this.attribute.value = value;
    if (this.args.onUpdate) {
      this.args.onUpdate(this.attribute);
    }
  }

  get listOptions() {
    // @ts-ignore
    if (this.descriptor?.id === 'brand') {
      return this.args.brandsList;
    }

    if (!this.descriptor?.listOptions) {
      return [];
    }

    return this.descriptor?.listOptions
      .filter((option: any) => {
        return (
          (this.isOptionAccessible(option) && !option.archived) ||
          option.id === this.attribute?.value
        );
      })
      .map((option: any) => ({
        text: option.label,
        value: option.id,
      }));
  }

  isOptionAccessible(option: any): boolean {
    if (!this.args.listOptions) {
      return true;
    }

    return this.args.listOptions?.[this.descriptor.id]?.includes(option.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::EditableAttribute': typeof EditableAttribute;
    'inbox2/ticket-attribute/editable-attribute': typeof EditableAttribute;
  }
}
