/* import __COLOCATED_TEMPLATE__ from './single-conversation-list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import type Session from 'embercom/services/session';

interface Args {
  conversation: Conversation;
}

interface Signature {
  Args: Args;
}

export default class SingleConversationList extends Component<Signature> {
  @service declare inbox2Counters: Inbox2Counters;
  @service declare inboxState: InboxState;
  @service declare session: Session;

  get showSeeAllButton(): boolean {
    return this.allInboxCount > 0 && !this.hasPricing5LiteSeat;
  }

  get allInboxCount(): number {
    return this.inbox2Counters.countForInbox({ id: 'all', type: InboxType.All });
  }

  get hasPricing5LiteSeat(): boolean {
    return this.session.teammate.hasPricing5LiteSeat;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::SingleConversationList': typeof SingleConversationList;
    'inbox2/left-nav/single-conversation-list': typeof SingleConversationList;
  }
}
