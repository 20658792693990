/* import __COLOCATED_TEMPLATE__ from './single-page-app-snippet-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { ternary } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  language: null,
  installationIsAnonymous: true,
  maybeComma: ternary('installationIsAnonymous', '', ','),
  name: 'Nikola Tesla',
  email: 'nikola@example.com',
  createdAt: '1312182000',
});
