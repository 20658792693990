/* import __COLOCATED_TEMPLATE__ from './wrap-up-banner.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type AdminAwayService from 'embercom/services/admin-away-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import { PhoneAwayStatusReasonTypes } from 'embercom/services/admin-away-service';
import { action } from '@ember/object';

interface Args {}

interface Signature {
  Element: HTMLButtonElement;
  Args: Args;
}

export default class WrapUpBanner extends Component<Signature> {
  @service declare adminAwayService: AdminAwayService;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare session: Session;
  @tracked showSuccessBanner = false;

  get shouldDisplayBanner() {
    return (
      this.adminAwayService.awayModeEnabled &&
      this.adminAwayService.isCurrentStatusAssociatedToPhoneStatus(
        PhoneAwayStatusReasonTypes.WrapUp,
      )
    );
  }

  @action setAvailable() {
    this.adminAwayService.toggleAwayMode();
    this.snackbar.notify(this.intl.t('inbox.wrap-up-banner.switch-to-active-success'));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::WrapUpBanner': typeof WrapUpBanner;
  }
}
