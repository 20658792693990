/* import __COLOCATED_TEMPLATE__ from './set-custom-away-reason.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type AdminAwayService from 'embercom/services/admin-away-service';
// @ts-ignore
import { type AwayStatusReason } from 'embercom/objects/inbox/away-reason';
import { tracked } from 'tracked-built-ins';

interface Args {
  item: {
    text: string;
    value?: AwayStatusReason;
    //TODO this is dependent on a workspace as sometimes the away reason might need to be set
    setAwayReason: (reason: AwayStatusReason | null) => void;
    awayStatusReasons: AwayStatusReason[];
  };
}

interface Signature {
  Args: Args;
}

export default class SetCustomAwayReasonPopover extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare adminAwayService: AdminAwayService;

  @tracked searchQuery = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.adminAwayService.setup();
  }

  get awayReasons() {
    return this.adminAwayService.awayStatusReasons.filter((reason) => {
      return reason.id === this.currentSelectedReasonId || !reason.deleted;
    });
  }

  get filteredAwayReasons() {
    return this.awayReasons.filter((reason) => {
      return (
        reason.label.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        reason.id === this.currentSelectedReasonId
      );
    });
  }

  get searchFieldVisible() {
    return this.awayReasons.length > 10;
  }

  get currentSelectedReasonId() {
    return this.args.item.value?.id;
  }

  get emptyDummyReason() {
    return {
      id: 'empty',
      label: this.intl.t('inbox.user-menu.set-away-reason'),
    } as AwayStatusReason;
  }

  @action setAwayReason(reason: AwayStatusReason) {
    let targetReason = this.currentSelectedReasonId === reason.id ? null : reason;
    this.args.item.setAwayReason(targetReason);
  }

  // This function is a Popper.js modifier (https://popper.js.org/docs/v2/modifiers/)
  // that keeps the dimensions of the app switcher popover the same as those of
  // the primary nav menu popover. This behavior is a design requirement from
  // the "early 2018" brand refresh.
  matchPopoverDimensionsToMenuPopover(popperModifierData: any) {
    let { reference: referenceElement, popper: popperElement } = popperModifierData.state.elements;
    let referenceElementPopoverContainer = referenceElement.closest('[data-popover-content]');
    popperElement.style.width = `${referenceElementPopoverContainer.offsetWidth}px`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::SetCustomAwayReason': typeof SetCustomAwayReasonPopover;
    'inbox2/user-menu/set-custom-away-reason': typeof SetCustomAwayReasonPopover;
  }
}
